<template>
  <v-card class="fill-height">
    <v-card-text>
      <div class="d-flex justify-end">
        <v-switch class="shrink mt-0" hide-details dense label="Publish" v-model="group.published" color="color3"></v-switch>
      </div>
      <v-text-field
        label="Group Name"
        v-model="group.name"
        color="color3"
      ></v-text-field>
      <v-radio-group v-model="group.type" :disabled="group.locked">
        <v-radio label="All teams" value="all" color="color3"></v-radio>
        <v-radio label="All teams not in previous groups" value="allx" v-if="groupI > 0" color="color3"></v-radio>
        <v-radio label="Let me choose the teams" value="spec" color="color3"></v-radio>
      </v-radio-group>
      <v-expand-transition>
        <div v-if="group.type === 'spec'">
          <v-autocomplete
            label="Teams"
            :items="division.activeTeams"
            item-value="id"
            item-text="name"
            v-model="group.teamIds"
            chips deletable-chips multiple
            color="color3"
            item-color="color3"
            :disabled="group.locked"
          >
          </v-autocomplete>
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div class="d-flex justify-end" v-if="group.locked">
          <unlock-dialog :standings="true" :warn="true" @unlock-click="onUnlockClick"></unlock-dialog>
        </div>
      </v-expand-transition>
    </v-card-text>
  </v-card>
</template>

<script>
import UnlockDialog from '@/components/Tournament/Pools/LockToggle.vue'

export default {
  props: ['group', 'groupI', 'division', 'standings'],
  computed: {
    showTeams () {
      return this.group && this.group.type === 'spec'
    }
  },
  methods: {
    onUnlockClick () {
      this.group.summary = null
      this.group.locked = false
      this.$emit('lock-click')
    }
  },
  components: {
    UnlockDialog
  }
}
</script>
