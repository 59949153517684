<template>
  <v-container fluid class="pa-0">
    <template v-if="groups.length > 1">
      <v-tabs
        slider-color="color1"
        show-arrows
        center-active
        v-model="tab"
        color="color3"
      >
        <v-tab
          v-for="(group, i) in groups"
          :key="i"
        >
          {{group.name}}
          <v-icon small class="ml-2" v-if="group.locked">fas fa-lock</v-icon>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(group, i) in groups"
          :key="i"
        >
          <group-display
            :group="group"
            :rounds="rounds"
            :teamIds="groupTeamsIds[i]"
            :division="division"
            :standings="standings"
            :i="i"
            :loading="loading"
            @lock-click="onLockClick"
          ></group-display>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <group-display
      v-else
      :group="groups[0]"
      :rounds="rounds"
      :teamIds="groupTeamsIds[0]"
      :division="division"
      :standings="standings"
      :i="0"
      :loading="loading"
      @lock-click="onLockClick"
    ></group-display>

  </v-container>
</template>

<script>
import flatten from '@/helpers/ArrayFlatten'
import GroupDisplay from './GroupDisplay'
import { mapGetters } from 'vuex'
import uniq from 'lodash.uniq'

export default {
  props: ['standings', 'division', 'tournament', 'loading'],
  data () {
    return {
      tab: null
    }
  },
  computed: {
    ...mapGetters(['view']),
    groups () {
      return this.standings && this.standings.groups ? this.view.admin ? this.standings.groups : this.standings.publishedGroups : []
    },
    rounds () {
      return this.standings && this.tournament && this.tournament.rounds.filter(f => this.standings.roundIds.includes(f.id))
    },
    availableTeams () {
      return this.division.isLeague ? uniq(flatten(this.division.activeTeams.map(m => m.id))) : uniq(flatten(this.rounds.map(m => m.allTeams.map(t => t.teamId))))
    },
    groupTeamsIds () {
      const result = []
      this.standings.groups.forEach(g => {
        const usedTeams = flatten(result)
        const teamIds = g.type === 'all' ? this.availableTeams : g.type === 'spec' ? g.teamIds : this.availableTeams.filter(f => !usedTeams.includes(f))
        result.push(teamIds)
      })
      return uniq(result)
    }
  },
  methods: {
    onLockClick () {
      this.$emit('lock-click')
    }
  },
  components: {
    GroupDisplay
  }
}
</script>
