<template>
  <v-container fluid class="pa-1" >
    <v-row dense>
      <v-col v-if="view.admin" class="text-right">
        <v-btn
          color="color3"
          text
          @click="preview = !preview"
          class="xsmall"
        >{{preview ? 'settings' : 'show preview'}}</v-btn>
      </v-col>
      <v-col cols="12" v-if="divisionStandings.length <= 100">
        <standings-display
          v-if="view.public || preview"
          :standings="myStandings"
          :division="division"
          :tournament="tournament"
          :loading="loading"
          @lock-click="saveMe"
        >
        </standings-display>
        <standings-settings
          v-else
          :standings="myStandings"
          :division="division"
          @lock-click="saveMe"
          @delete-click="onDeleteClick"
          :loading="loading"
        ></standings-settings>
      </v-col>
      <v-col cols="12" v-else>
        <v-expansion-panels
          v-model="panel"
          multiple
        >
          <!-- EXISTING ROUNDS -->
          <v-expansion-panel
            v-for="(stand, i) in divisionStandings"
            :key="i"
            :id="`stand${i}`"
            :style="i > 0 ? 'border-top: 1px solid white !important' : null"
            ripple
          >
            <v-expansion-panel-header color="color1 color1Text--text" class="title">
              {{stand.title}}
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <standings-display
                v-if="view.public || preview"
                :standings="stand"
                :division="division"
                :tournament="tournament"
              >
              </standings-display>
              <standings-settings
                v-else
                :standings="stand"
                :division="division"
              ></standings-settings>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-btn
      color="success"
      fab fixed bottom right
      v-if="(dirty || loading) && view.admin"
      @click.stop="saveMe"
      :loading="loading"
    >
      <v-icon>fas fa-save</v-icon>
    </v-btn>
    <v-btn
      color="error"
      style="margin-right: 60px"
      fab fixed bottom right
      v-if="(dirty || loading) && view.admin"
      :disabled="loading"
      @click.stop="cancel"
    >
      <v-icon>fas fa-times</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import { mapGetters } from 'vuex'
import StandingsDisplay from '@/components/Standings/Display'
import StandingsSettings from '@/components/Standings/StandingsSettings'

export default {
  mixins: [RouteMixin],
  data () {
    return {
      loading: false,
      panel: [0],
      preview: false,
      og: false
    }
  },
  computed: {
    ...mapGetters(['tournament', 'getDivisionJProp', 'view', 'getDivision']),
    division () {
      return this.getDivision(this.divisionId)
    },
    divisionStandings () {
      if (this.og) return this.divisionStandingsOG
      return this.division && this.division.standings
    },
    myStandings () {
      return this.divisionStandings && this.divisionStandings[this.standingsI]
    },
    complete () {
      return !!(this.myStandings.complete && !this.myStandings.locked)
    },
    divisionStandingsOG () {
      return this.tournament.standings && this.tournament.standings.filter(f => f.divisionId === this.divisionId)
    },
    savedStandings () {
      return this.division && this.division.info
    },
    a () {
      return JSON.stringify(this.divisionStandings.map(m => m.dto))
    },
    b () {
      return this.savedStandings
    },
    aOG () {
      return JSON.stringify(this.tournament.standings.map(m => m.dto))
    },
    bOG () {
      return JSON.stringify(this.tournament.jProps && this.tournament.jProps.standings)
    },
    dirty () {
      return this.a !== this.savedStandings
    },
    dto () {
      return { info: this.og ? this.aOG : this.a }
    }
  },
  methods: {
    saveMe () {
      this.loading = true
      this.division.patch(this.dto)
        .then(r => this.division.update(this.dto))
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    cancel () {
      this.division.initStandings()
      if (!this.savedStandings) {
        this.$router.push({ name: this.view.admin ? 'division-home' : 'division-teams', params: { tournamentId: this.tournamentId, divisionId: this.divisionId } })
      }
    },
    onLockToggle () {
      console.log('lock me')
    },
    onDeleteClick () {
      this.loading = true
      this.division.patch({ info: null })
        .then(r => this.division.update({ info: null }))
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    }
  },
  components: {
    StandingsSettings,
    StandingsDisplay
  },
  mounted () {
    if (!(this.divisionStandings && this.divisionStandings.length > 0)) {
      this.$router.push({ name: this.view.admin ? 'division-home' : 'division-teams', params: { tournamentId: this.tournamentId, divisionId: this.divisionId } })
    }
    if (this.savedStandings) this.preview = true
  }
}
</script>
