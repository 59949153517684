<template>
  <v-container fluid v-if="model">
    <v-row dense>
      <v-col cols="12">
        <v-text-field
          label="Title"
          v-model="model.title"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-select
          label="Matches to include"
          :items="division.rounds"
          item-text="name"
          item-value="id"
          v-model="model.roundIds"
          chips deletable-chips multiple
          color="color3"
          item-color="color3"
          :disabled="disabled"
          hint="All matches from the selected rounds will be considered"
          persistent-hint
        >
        </v-select>
      </v-col>
      <v-col cols="12">
        <v-select
          v-if="user && user.id === 1"
          label="Finishes to consider"
          :items="division.rounds"
          item-text="name"
          item-value="id"
          v-model="model.finishRoundIds"
          chips deletable-chips multiple
          color="color3"
          item-color="color3"
          :disabled="disabled"
          hint="All round finishes from the selected rounds will be considered"
          persistent-hint
        >
        </v-select>
      </v-col>
      <v-col cols="12">
        <tiebreak-select
          v-model="tiebreaks"
          :disabled="disabled"
        >
        </tiebreak-select>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6" lg="4" xl="3"
        v-for="(group, i) in model.groups"
        :key="`group${i}`"
      >
        <standings-group-settings
          :group="group"
          :groupI="i"
          :division="division"
          :standings="standings"
          @lock-click="onLockClick"
        ></standings-group-settings>
      </v-col>
      <v-col cols="12" sm="6" lg="4" xl="3">
        <v-card color="grey lighten-3" class="fill-height d-flex justify-center align-center">
          <v-btn color="color3" text @click.stop="addGroup">Add A group</v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-end pa-0">
        <v-btn color="error" x-small text @click.stop="deleteMe = true">delete</v-btn>
      </v-col>
    </v-row>
    <vbl-confirm-dialog
      :ask="deleteMe"
      @deny="deleteMe = false"
      @confirm="doDelete"
      :loading="loading"
    ></vbl-confirm-dialog>
  </v-container>
</template>

<script>
import StandingsGroupSettings from './StandingsGroupSettings'
import Tiebreaks from '@/classes/Tiebreaks'
import TiebreakSelect from './TiebreakSelect'

import { mapGetters } from 'vuex'

export default {
  props: ['standings', 'division', 'loading'],
  data () {
    return {
      editing: false,
      deleteMe: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    model () {
      return this.standings
    },
    isDuals () {
      return this.division.isDuals
    },
    tiebreaks: {
      get () {
        const base = this.standings.tiebreaks || `${this.division.isDuals ? 'DUAL_RECORD,' : ''}MATCH_WIN_RATIO,SET_WIN_RATIO,HEAD_2_HEAD,OVERALL_POINTS,ORIGINAL_SEED`
        return base.split(',')
      },
      set (val) {
        this.standings.tiebreaks = val.join(',')
      }
    },
    disabled () {
      return !this.standings.editable
    },
    teams () {
      return [
        { id: '*', name: 'All Teams' },
        ...this.division.activeTeams
      ]
    },
    tiebreakOptions () {
      return Tiebreaks
    }
  },
  methods: {
    addGroup () {
      this.standings.addGroup()
    },
    onLockClick () {
      this.$emit('lock-click')
    },
    doDelete () {
      this.$emit('delete-click')
    }
  },
  components: {
    StandingsGroupSettings,
    TiebreakSelect
  }
}
</script>
